<template>
  <v-container fluid>

      <v-row>
        <v-col cols="12" class="ma-0 py-0">
          <v-card elevation="0">
            <v-breadcrumbs :items="items" divider="/"></v-breadcrumbs>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <base-material-card
            :title="$vuetify.lang.t('$vuetify.orderComment')"
            icon="mdi-apple-finder"
          >
            <v-row>
                <v-col cols="12" xl="4" lg="4" md="3" sm="6" xs="6">
                <v-sheet>
                    <v-toolbar-items>
                    <v-text-field
                        :label="$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        append-icon="mdi-magnify"
                    />
                    </v-toolbar-items>
                </v-sheet>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="3" sm="6" xs="6">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        :nudge-top="30"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                :label="$vuetify.lang.t('$vuetify.startDate') + ' ~ ' + $vuetify.lang.t('$vuetify.endDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                clearable
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="date = null"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            range
                            @input="menu = false"
                            class="ma-0 pa-0"
                            :locale="this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'"
                        >

                        </v-date-picker>
                    </v-menu>
                </v-col>  
                <v-col cols="12" xl="4" lg="4" md="3" sm="10" xs="10">
                    <v-select
                    :items="statuses"
                    :label="$vuetify.lang.t('$vuetify.status')"
                    ></v-select>
                </v-col>
            </v-row>

            <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
            />
        </base-material-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { orderList, orderDelete, orderExport, orderExports } from "@/api/order";

export default {
  name: "orderComment",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    },
    data() {
        return {
        statuses: ['All', 'Customer creation/pending confirmation', 'Confirmed/to be reviewed', "Reviewed/pending", "order producting", "off the stocks"],
        date: new Date().toISOString().substr(0, 10),
        menu: false,
        dialog: false,
        dialogProduct: false,
        dialogDetail: false,
        dialogShipping: false,
        headers: [
            {
                text: this.$vuetify.lang.t("$vuetify.itemNo"),
            },
            {
                text: this.$vuetify.lang.t("$vuetify.orderNo"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.Products"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.number"), 
            }, 
            {
                text: this.$vuetify.lang.t("$vuetify.totalPrice1"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.currency"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.status"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.orderDate"), 
            },
            {
                text: this.$vuetify.lang.t("$vuetify.actionState"), 
            },
        ],
        datas: [],
        search: "",
        items: [
            { text: this.$vuetify.lang.t("$vuetify.home") },
            { text: this.$vuetify.lang.t("$vuetify.user") },
            { text: this.$vuetify.lang.t("$vuetify.orderComment") },
        ],
        // getData() {
        // orderList()
        //   .then((data) =>{ 
        //   this.datas = [...data.items];
          
        //     for (var i = 0; i < this.datas.length; i++) {
        //       this.datas[i].itemNo = i+1;
        //     }
        //   })
        //   .catch((err) => console.log(err));
        // },
      };
    },
    mounted() {
      this.getData();
    },
};
</script>